import React, { useState } from 'react';
import { 
  Navbar, 
  Nav, 
  Container, 
  Button, 
  Card,
  Row, 
  Col,
  Image
} from 'react-bootstrap';
import { Menu, X } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate
} from "react-router-dom";

// Navigation Component
const Navigation = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    setExpanded(false);
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        const navHeight = 56; // Bootstrap navbar height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navHeight;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }, 100);
  };

  const handleNavClick = (page, sectionId = null) => {
    if (sectionId) {
      scrollToSection(sectionId);
    } else {
      navigate(page);
      setExpanded(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Navbar 
      bg="white" 
      expand="sm" 
      fixed="top" 
      expanded={expanded}
      onToggle={setExpanded}
      className="shadow-sm"
    >
      <Container>
        <Navbar.Brand 
          as={Button} 
          variant="link" 
          onClick={() => handleNavClick('/')}
          className="text-primary fs-4 fw-bold text-decoration-none"
        >
          <img src={require('./stagekred-logo.svg').default} alt="" width={200}/>
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          {expanded ? <X size={24} /> : <Menu size={24} />}
        </Navbar.Toggle>
        
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => handleNavClick('/', 'about')}>About</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/', 'features')}>Features</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('privacy')}>Privacy</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('terms')}>Terms</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/', 'contact')}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

// Hero Component
const Hero = () => (
  <div className="position-relative" style={{ height: '600px' }}>
    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white"
         style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <h1 className="display-4 fw-bold mb-4">Welcome to Stagekred</h1>
      <p className="fs-4 mb-4">Your Ultimate #Challenge Platform</p>
      <div className="d-flex gap-3">
        <Button variant="dark" href="#" className="px-4">
          Download on App Store
        </Button>
        <Button variant="dark" href="#" className="px-4">
          Get it on Google Play
        </Button>
      </div>
    </div>
  </div>
);

// About Component
const About = () => (
  <section className="py-5 bg-light" id="about">
    <Container>
      <h2 className="text-center fw-bold mb-4">About Stagekred</h2>
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <p className="fs-5 text-muted">
            Stagekred is a one-of-a-kind platform that brings together performers and audiences in an exciting and competitive environment. Whether you're a singer, dancer, or any kind of performer, Stagekred offers you a space to showcase your talents through our unique Challenges.
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

// Features Component
const Features = () => {
  const features = [
    {
      title: "Challenges",
      description: "1. Participants can join any active challenge, submit their best video performance, and compete for the prize offered by the challenge organizer.\n2. Organizers set up the challenges by providing a prize and a background song that participants will perform to.",
      image: "./assets/image1.jpg",
      imageLeft: true
    },
    {
      title: "Voting & Winning",
      description: "The participant with the most votes at the end of the challenge wins the prize, which is then transferred to their Stagekred wallet.",
      image: "./assets/image2.jpg",
      imageLeft: false
    },
    {
      title: "Secure Payouts",
      description: "Winners can easily transfer their cash prize from their wallet to their bank account",
      image: "./assets/image3.jpg",
      imageLeft: true
    }
  ];

  return (
    <section className="py-5" id="features">
      <Container>
        <h2 className="text-center fw-bold mb-5">Features</h2>
        <div className="d-flex flex-column gap-5">
          {features.map((feature, index) => (
            <Row key={index} className={`align-items-center ${index % 2 ? 'flex-row-reverse' : ''}`}>
              <Col md={6} style={{textAlign:'center'}}>
                <Image style={{maxHeight:300}} src={require(`${feature.image}`)} alt={feature.title} fluid rounded className="shadow" />
              </Col>
              <Col md={6}>
                <h3 className="fw-bold mb-3">{feature.title}</h3>
                <p className="text-muted">{feature.description}</p>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </section>
  );
};

// Footer Component
const Footer = () => (
  <footer className="bg-dark text-white py-5" id="contact">
    <Container>
      <Row>
        <Col md={4}>
          <h3 className="fw-bold mb-3">Contact Us</h3>
          <p>Email: info@stagekred.com</p>
          <p>Phone: +1 (512) 815-2272</p>
        </Col>
        <Col md={4}>
          <h3 className="fw-bold mb-3">Follow Us</h3>
          <div className="d-flex flex-column gap-2">
            <p>X</p>
            <p>Facebook</p>
            <p>Instagram</p>
          </div>
        </Col>
        <Col md={4}>
          <h3 className="fw-bold mb-3">Address</h3>
          <p>16631 vance jackson rd</p>
          <p>San Antonio, Texas 78257</p>
          <p>United States</p>
        </Col>
      </Row>
      <Row className="mt-4 pt-4 border-top">
        <Col className="text-center">
          <p>&copy; 2024 Stagekred. All rights reserved.</p>
        </Col>
      </Row>
    </Container>
  </footer>
);

// Privacy Policy Component
const PrivacyPolicy = () => (<>
  <Navigation/>
  <Container style={{marginTop:80}} className="py-5">
    <Row className="justify-content-center">
      <Col md={8}>
        <h1 className="fw-bold mb-4">Privacy Policy</h1>
        <Card>
          <Card.Body>
            <p>
              Your Privacy Matters at Stagekred. We are committed to protecting your personal information and ensuring transparency about how we collect, use, and share your data. This Privacy Policy outlines the measures we take to safeguard your information and how we respect your rights.
            </p>
            
            <p>
              1. <b>Information We Collect</b>: To ensure a secure and authentic experience, Stagekred requires users to verify their accounts using an email address. This information is essential for account validation and helps us maintain fair participation standards. Only real and valid emails can be used for verification, and each email can be associated with only one account. We also collect additional data related to your interactions with the app, including the challenges you participate in, the votes you cast, and any prize money you may win. This information is necessary for enhancing user experience and managing challenge operations.
            </p>
            
            <p>
              2. <b>How We Use Your Information</b>: We use your email primarily for account verification purposes and to prevent fraudulent activity on Stagekred. Personal data is also utilized to facilitate secure challenge participation, process prize transfers, and enable efficient communication between participants and challenge organizers. Additionally, we may analyze anonymized data to improve app functionality and provide a better user experience.
            </p>
            
            <p>
              3. <b>Data Sharing</b>: At Stagekred, your privacy is our priority. We do not sell or share your personal information with third-party marketers. Under certain circumstances, we may share specific data with trusted service providers who assist in processing payments or improving app functionality. Rest assured, all data sharing is conducted securely and within the boundaries of your consent. Each partner we engage undergoes a stringent vetting process to ensure data protection standards.
            </p>
            
            <p>
              4. <b>Security</b>: Protecting your data is paramount at Stagekred. We utilize industry-standard encryption methods to safeguard your personal and financial information from unauthorized access. Our team continuously monitors and enhances our security measures to address emerging threats. In addition to encryption, we employ firewalls, secure servers, and other state-of-the-art security technologies to ensure your data remains private.
            </p>
            
            <p>
              5. <b>Your Rights</b>: Stagekred values your control over your personal data. You have the right to request access to or deletion of your personal data at any time. Our support team is available to address any concerns or assist with requests regarding your data rights.
            </p>
            
            <p>
              6. <b>Data Retention</b>: We retain your personal data only for as long as necessary to provide our services, meet legal obligations, and resolve disputes. Once your information is no longer needed for these purposes, we securely delete or anonymize it to protect your privacy. You may contact our support team if you wish to expedite data deletion under certain conditions.
            </p>
            
            <p>
              7. <b>Changes to Our Privacy Policy</b>: Stagekred may update this Privacy Policy periodically to reflect changes in data practices or legal requirements. We will notify users of significant updates through the app or via email. Your continued use of the app following these updates constitutes your acceptance of the revised Privacy Policy.
            </p>
            
            <p>
              By using Stagekred, you consent to the collection, use, and handling of your information as outlined in this Privacy Policy. If you have any questions, concerns, or require additional clarification, please feel free to reach out to us through the app’s support section. We are dedicated to maintaining your privacy and transparency at every step.
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container></>
);

// Terms and Conditions Component
const TermsAndConditions = () => (<>
  <Navigation />
  <Container style={{marginTop:80}} className="py-5">
    <Row className="justify-content-center">
      <Col md={8}>
        <h1 className="fw-bold mb-4">Terms and Conditions</h1>
        <Card>
          <Card.Body>
            <p>
              Welcome to Stagekred! By using our platform, you agree to the following Terms and Conditions. Please read them carefully before participating in any challenge or using any features on Stagekred.
            </p>
            
            <p>
              1. <b>Eligibility</b>: Users must be at least 14 years old to participate or organize challenges. Only users who have verified their account are eligible to vote and participate. By agreeing to these terms, you confirm that you meet this eligibility requirement. Accounts found to have falsified information will be subject to suspension or termination.
            </p>
            
            <p>
              2. <b>Challenge Participation</b>: Users may enter any active challenge and submit their video performance, adhering to any guidelines set by the challenge organizer. Stagekred reserves the right to disqualify entries that violate platform standards, including but not limited to abusive, offensive, or plagiarized content. Stagekred will notify users if their content has been flagged or removed.
            </p>
            
            <p>
              3. <b>Challenge Organizers</b>: Organizers are responsible for providing an accurate description of the challenge, including the cash prize and background music. The prize must be transferable to the winner’s Stagekred wallet at the conclusion of the challenge. Organizers must also ensure that any copyrighted material used in the challenge complies with intellectual property laws.
            </p>
            
            <p>
              4. <b>Voting</b>: Only verified users are eligible to vote on performances. Stagekred utilizes security measures to verify user identities and prevent voting manipulation. Any attempt to interfere with the voting process will result in immediate disqualification from the challenge and potential account suspension.
            </p>
            
            <p>
              5. <b>Prizes</b>: Prize money or rewards are transferred to the winner’s Stagekred wallet after the challenge ends. Winners are responsible for ensuring their bank information is accurate for transfer purposes. Stagekred is not liable for lost or delayed transfers due to inaccurate information provided by users. All transfers to bank accounts are subject to processing fees as outlined on the platform.
            </p>
            
            <p>
              6. <b>Content Ownership and Licensing</b>: By submitting content on Stagekred, users grant us a non-exclusive, worldwide, royalty-free license to display, distribute, and promote the content on our platform and through our marketing channels. Users retain ownership of their content. Any unauthorized use of another user's content without permission is strictly prohibited and may result in legal action.
            </p>
            
            <p>
              7. <b>Account Termination</b>: Stagekred reserves the right to suspend or terminate any account found to be in violation of these Terms, including but not limited to voting fraud, creating multiple accounts to boost votes for a particular performer or self, or engaging in any form of cheating or misrepresentation. Stagekred may terminate accounts without notice if deemed necessary to maintain platform integrity.
            </p>
            
            <p>
              8. <b>Privacy Policy</b>: Stagekred is committed to protecting user privacy and data. By using our services, you consent to the collection, storage, and use of your information as outlined in our Privacy Policy. We will not share personal information with third parties without user consent unless required by law.
            </p>
            
            <p>
              9. <b>Platform Updates</b>: Stagekred may introduce new features or update existing ones to improve user experience. Any changes to features or terms will be communicated to users via email or in-app notifications. Continued use of the platform after updates constitutes acceptance of the new terms.
            </p>
            
            <p>
              10. <b>Limitation of Liability</b>: Stagekred and its affiliates will not be liable for any indirect, incidental, or punitive damages arising from the use of our platform. Users agree to use the platform at their own risk. This limitation applies to all claims, including but not limited to those related to accident experienced by a user during the filming of video performance, or any incident that may occur during performances. Users are encourage to be cautious and perform in a responsible way to prevent accident and or injuries.
            </p>
            
            <p>
              11. <b>Governing Law</b>: These terms are governed by the laws of the jurisdiction in which Stagekred operates. Any disputes arising from the use of the platform or these terms will be resolved through mediation or arbitration in accordance with local laws.
            </p>
            
            <p>
              By using Stagekred, you acknowledge that you have read, understood, and agree to these Terms and Conditions. Violations of any terms may result in disqualification, account suspension, or further legal action as necessary.
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  </>
);

// Main App Component
const WebApp = () => {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <Navigation />
      <div className="pt-5">
          <>
            <Hero />
            <About />
            <Features />
          </>
      </div>
      <Footer />
    </div>
  );
};


const router = createBrowserRouter([
  {
    path: "/",
    element: <WebApp />,
  },
  {
    path: "terms",
    element: <TermsAndConditions />,
  },
  {
    path: "privacy",
    element: <PrivacyPolicy />,
  },
]);

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;